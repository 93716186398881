<template>
  <!-- <el-affix :offset="120"> -->
  <div id="infoDiv" class="infoDiv affix">
    <img src="@/assets/wifeMs.png" alt="抓不到我哦" />
  </div>
  <!-- </el-affix> -->
</template>

<script lang="ts" setup></script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.infoDiv {
  background-color: #eee;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}

.infoDiv img {
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: auto;
}
</style>
