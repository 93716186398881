import { AxiosInstance } from 'axios'
import MockAdapter from 'axios-mock-adapter'

// import AuthResponse from './json/authResponse.json'
// import CcMenuResponse from './json/ccMenuResponse.json'
// import SplAuthResponse from './json/splAuthResponse.json'
// import UserEditResponse from './json/userEditResponse.json'
// import UserManageResponse from './json/userManageResponse.json'

export default {
  run: (cbsaxios: AxiosInstance) => {
    const mockAxios = new MockAdapter(cbsaxios)

    // /** 簡易認証 */
    // mockAxios
    //   // 初期処理
    //   .onGet('simpleAuthentication/initialDisplay')
    //   .reply(200, SplAuthResponse.initialDisplay)
    //   // 簡易認証処理
    //   .onPost('simpleAuthentication/verify')
    //   .reply(200)

    // /** CCログイン */
    // mockAxios
    //   // 初期処理
    //   .onGet('authentication/initialDisplay')
    //   .reply(200, AuthResponse.initialDisplay)
    //   // ログイン処理
    //   .onPost('authentication/login')
    //   .reply(200)

    // /** CCメニュー */
    // mockAxios
    //   // 初期処理
    //   .onGet('ccMenu/initialDisplay')
    //   .reply(200, CcMenuResponse.initialDisplay)
    //   // ログイン状態維持処理
    //   .onPost('ccMenu/loginKeep')
    //   .reply(200)
    //   // ログアウト処理
    //   .onPost('ccMenu/logout')
    //   .reply(200)

    /** ユーザ管理 */
    mockAxios
      //検索画面 初期処理
      .onGet('/userManage/userSearch/initialDisplay')
      .reply(200, 100)
      // // 検索ボタン押下処理
      // .onPost('/userManage/userSearch/search')
      // .reply(200, UserManageResponse.search)
      // // 編集画面 初期処理
      // .onPost('/userManage/userEdit/initialEdit')
      // .reply(200, UserEditResponse.initialEdit)
      // // 編集画面 相関チェック処理
      // .onPost('/userManage/userEdit/returnDisplay')
      // .reply(400, UserEditResponse.returnDisplay)
      // // 編集画面 ロック解除処理
      // .onPost('/userManage/userEdit/lockClear')
      // .reply(200)
      // // 編集画面 ユーザ情報登録/更新ボタン押下処理
      // .onPost('/userManage/userEdit/editUserInfo')
      // .reply(200)
      // // 編集画面 グループ追加ボタン押下処理
      // .onPost('/userManage/userEdit/editAddGroup')
      // .reply(200, UserEditResponse.editAddGroup)
      // // 編集画面 グループ更新ボタン押下処理
      // .onPost('/userManage/userEdit/editUpdateGroup')
      // .reply(200, UserEditResponse.editUpdateGroup)
      // // 編集画面 グループ削除ボタン押下処理
      // .onPost('/userManage/userEdit/editDeleteGroup')
      // .reply(200, UserEditResponse.editDeleteGroup)
      // // 編集画面 ユーザタイプ追加ボタン押下処理
      // .onPost('/userManage/userEdit/editAddUserType')
      // .reply(200, UserEditResponse.editAddUserType)
      // // 編集画面 ユーザタイプ更新ボタン押下処理
      // .onPost('/userManage/userEdit/editUpdateUserType')
      // .reply(200, UserEditResponse.editUpdateUserType)
      // // 編集画面 ユーザタイプ削除ボタン押下処理
      // .onPost('/userManage/userEdit/editDeleteUserType')
      // .reply(200, UserEditResponse.editDeleteUserType)
      // // 編集画面 役割追加ボタン押下処理
      // .onPost('/userManage/userEdit/editAddYakuwari')
      // .reply(200, UserEditResponse.editAddYakuwari)
      // // 編集画面 役割更新ボタン押下処理
      // .onPost('/userManage/userEdit/editUpdateYakuwari')
      // .reply(200, UserEditResponse.editUpdateYakuwari)
      // // 編集画面 役割削除ボタン押下処理
      // .onPost('/userManage/userEdit/editDeleteYakuwari')
      // .reply(200, UserEditResponse.editDeleteYakuwari)
  },
}
