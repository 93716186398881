import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import i18n from "./locales";

const app = createApp(App);

//ElementPlusIconsVue
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app
  .use(store)
  .use(router)
  .use(ElementPlus)
  .use(CKEditor)
  .use(i18n)
  .mount("#app");
