/**
 * Article Types
 */
export type ArticleResults = {
  // UUID
  uuid: string;
  // タイトル
  title: string;
  // 主題
  theme: string;
  // 言語
  language: string;
  // 削除フラグ
  deleteFlag: boolean;
  // 隠すフラグ
  draftFlag: boolean;
  // 概要
  summary: string;
  // 文章内容
  content: string;
  // タグ
  tags: string[];
  // 訪問数
  visits: number;
  // コメント数
  comments: number;
  // 新規日時
  createTime: number;
};

/**
 * 初期化された{ArticleResults}を取得します。
 * @returns
 */
export const initArticleResults = (): ArticleResults => {
  const init: ArticleResults = {
    // UUID
    uuid: "",
    // タイトル
    title: "",
    // 主題
    theme: "",
    // 言語
    language: "",
    // 削除フラグ
    deleteFlag: false,
    // 隠すフラグ
    draftFlag: false,
    // 概要
    summary: "",
    // 文章内容
    content: "",
    // タグ
    tags: [],
    // 訪問数
    visits: 0,
    // コメント数
    comments: 0,
    // 新規日時
    createTime: 0,
  };
  return structuredClone(init);
};

/**
 * Article一覧結果 レスポンスTypes
 */
export type ArticleResultsResponse = {
  // 結果リスト
  articles: ArticleResults[];
  totalCount: number;
};

/**
 * KeySortResults
 */
export type KeySortResults = {
  // 年
  year: number;
  // タイトル
  articles: ArticleResults[];
};

/**
 * KeySearchResults
 */
export type KeySearchResults = {
  // articlesSort
  articlesSort: KeySortResults[];

  totalCount: number;
};

/**
 * TagResults
 */
export type TagResults = {
  // count
  count: number;
  // タグ
  tag: string;
};

/**
 * KeySearchResults
 */
export type ArchiveResults = {
  // articlesSort
  articlesSort: KeySortResults[];

  tagGroup: TagResults[];
};
