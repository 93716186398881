import { ArticleSearchRequest } from "../apitypes/request/ArticleSearchRequest";
import { ArchiveResults } from "../apitypes/response/ArticleResponse";
import Request from "../axios";
import { AxiosResponse } from "axios";

const request = new Request({
  // baseURL: import.meta.env.BASE_URL,
  timeout: 1000 * 60 * 5,
  interceptors: {
    // 请求拦截器
    requestInterceptors: (config) => config,
    // 响应拦截器
    responseInterceptors: (result: AxiosResponse) => {
      return result;
    },
  },
});

//タグでArticle検索
export const postArticlesArchive = async (params: ArticleSearchRequest) => {
  return await request.post<ArchiveResults>(
    "/provider/getArticlesArchive",
    params
  );
};
