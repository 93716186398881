import {
  ArticleSearchRequest,
  ArticlePagingSearchRequest,
} from "../apitypes/request/ArticleSearchRequest";
import { LoginModel } from "../apitypes/request/AccountRequest";
import {
  ArticleResults,
  ArticleResultsResponse,
} from "../apitypes/response/ArticleResponse";
import { CommonResponse } from "../apitypes/response/AccountResponse";
import Request from "../axios";
import { AxiosResponse } from "axios";

const request = new Request({
  // baseURL: import.meta.env.BASE_URL,
  timeout: 1000 * 60 * 5,
  interceptors: {
    // 请求拦截器
    requestInterceptors: (config) => config,
    // 响应拦截器
    responseInterceptors: (result: AxiosResponse) => {
      return result;
    },
  },
});

//全Article検索
export const postAllArticles = async () => {
  return await request.get<ArticleResultsResponse>("/provider/getAllArticle");
};

//ページングでArticle検索
export const postPageArticles = async (params: ArticlePagingSearchRequest) => {
  return await request.post<ArticleResultsResponse>(
    "/provider/getArticlesByPage",
    params
  );
};

//単一Articles検索
export const postSingleArticle = (params: ArticleSearchRequest) => {
  return request.post<ArticleResults>(
    "/provider/getSingleArticle",
    params
  );
};

//主題でArticles検索
export const postThemeArticle = async (params: ArticleSearchRequest) => {
  return await request.post<ArticleResultsResponse>(
    "/provider/getArticleByTheme",
    params
  );
};

//ユーザーログイン
export const postLogin = async (params: LoginModel) => {
  return await request.post<CommonResponse>("/custome/login", params);
};

//ユーザーログアウト
export const postLogout = async () => {
  return await request.get<CommonResponse>("/custome/logout");
};

//文章削除
export const postDelete = async (params: ArticleSearchRequest) => {
  return await request.post("/provider/deleteArticle", params);
};

//文章隠す
export const postHide = async (params: ArticleSearchRequest) => {
  return await request.post("/provider/hideArticle", params);
};

//検索ボタン押下
export const postArticlesSearch = async (params: ArticleSearchRequest) => {
  return await request.post<ArticleResultsResponse>(
    "/userManage/userSearch/search",
    params
  );
};
