import { ArticleEditRequest } from "../apitypes/request/ArticleEditRequest";
// import {
//   UserManageResponse,
//   ArticleResultsResponse,
// } from "../apitypes/response/ArticleResponse";
import Request from "../axios";
import { AxiosResponse } from "axios";
import { CommonResponse } from "../apitypes/response/AccountResponse";

const request = new Request({
  // baseURL: import.meta.env.BASE_URL,
  timeout: 1000 * 60 * 5,
  interceptors: {
    // 请求拦截器
    requestInterceptors: (config) => config,
    // 响应拦截器
    responseInterceptors: (result: AxiosResponse) => {
      return result;
    },
  },
});

//全Article検索
export const getPermissions = async () => {
  return await request.get<CommonResponse>("/custome/getPermissions");
};

//提交文章押下
export const postArticlesSave = async (param: ArticleEditRequest) => {
  return await request.post("/provider/saveArticle", param);
};
