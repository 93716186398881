<template>
  <Waline
    :serverURL="serverURL"
    :path="path"
    :reaction="[
      'https://unpkg.com/@waline/emojis@1.2.0/bilibili/bb_thumbsup.png',
      'https://unpkg.com/@waline/emojis@1.2.0/bilibili/bb_no_mouth.png',
      'https://unpkg.com/@waline/emojis@1.2.0/bilibili/bb_heart_eyes.png',
      'https://unpkg.com/@waline/emojis@1.2.0/bilibili/bb_tiaokan.png',
      'https://unpkg.com/@waline/emojis@1.2.0/bilibili/bb_scared.png',
    ]"
    :lang="locale"
    :emoji="[
      'https://unpkg.com/@waline/emojis@1.2.0/bilibili',
      'https://unpkg.com/@waline/emojis@1.2.0/tw-emoji',
    ]"
  />
</template>
<script setup>
import { Waline } from "@waline/client/component";
import { computed } from "vue";
import { useRoute } from "vue-router";

import "@waline/client/style";

const langSto = localStorage.getItem("lang");

const locale = langSto === "ja" ? "jp" : langSto;

const serverURL = "https://comment.tyoumisaka.cc/";

const path = computed(() => useRoute().path);
</script>
