import axios from "axios";
import type {
  AxiosInstance,
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
  AxiosResponse,
} from "axios";
import mockAxios from "./mock/MockAxiosAdapter";
import type { CreateRequestConfig } from "./config";
import { ElMessage } from "element-plus";

if (process.env.NODE_ENV === "development") {
  axios.defaults.baseURL = "http://localhost:8060";
} else if (process.env.NODE_ENV === "production") {
  axios.defaults.baseURL = "https://api.tyoumisaka.cc/";
}

interface BlogRequestConfig<T> extends InternalAxiosRequestConfig {
  isToken?: boolean;
}

class Request {
  // axios 实例
  instance: AxiosInstance;

  constructor(config: CreateRequestConfig) {
    this.instance = axios.create(config);

    // axiosのMock実装
    if (process.env.VUE_APP_USE_MOCK == "true") {
      console.log("axiosをmockで起動します");
      mockAxios.run(this.instance);
    }

    this.instance.interceptors.request.use(
      (config: BlogRequestConfig<any>) => {
        const { isToken = true } = config;
        // token，用于后端的验证
        const token = localStorage.getItem("token") as string;
        if (token && isToken) {
          config.headers!.authorization = token;
        }

        return config;
      },
      (err: any) => {
        // 请求错误，可用全局提示框进行提示
        return Promise.reject(err);
      }
    );

    this.instance.interceptors.response.use(
      (res: AxiosResponse) => {
        // 直接返回res或者res.data
        // 处理自定义code
        return res;
      },
      (err: any) => {
        // 这处理http常见错误，进行全局提示
        let message = "";
        switch (err.response.status) {
          case 400:
            message = "请求错误(400)";
            break;
          case 401:
            message = "未授权，请重新登录(401)";
            // 清空storage并跳转到登录页
            break;
          case 403:
            message = "拒绝访问(403)";
            break;
          case 404:
            message = "请求出错(404)";
            break;
          case 408:
            message = "请求超时(408)";
            break;
          case 500:
            message = "服务器错误(500)";
            break;
          case 501:
            message = "服务未实现(501)";
            break;
          case 502:
            message = "网络错误(502)";
            break;
          case 503:
            message = "服务不可用(503)";
            break;
          case 504:
            message = "网络超时(504)";
            break;
          case 505:
            message = "HTTP版本不受支持(505)";
            break;
          default:
            message = `连接出错(${err.response.status})!`;
        }
        // 这里错误消息可以使用全局弹框展示出来
        // 比如element plus 可以使用 ElMessage
        ElMessage({
          showClose: true,
          message: `${message}，请检查网络或联系管理员！`,
          type: "error",
        });
        // 这里是AxiosError类型，所以一般我们只reject我们需要的响应即可
        return Promise.reject(err.response);
      }
    );
  }
  request(config: InternalAxiosRequestConfig) {
    return this.instance.request(config);
  }
  get<T = any>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.instance.get(url, config);
  }

  post<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.instance.post(url, data, config);
  }
}

/**
 * axiosエラーから共通エラーデータを取得
 */
// export const getCommonErrorResponse = (error: unknown) => {
//   if (axios.isAxiosError(error)) {
//     const errorResponseData = error.response?.data
//     if (isCommonErrorResponse(errorResponseData)) {
//       return errorResponseData
//     } else {
//       return null
//     }
//   }
//   return null
// }

export default Request;
