export default {
  buttons: {
    login: "ログイン",
  },
  menus: {
    home: {
      name: "ホーム",
      hitokoto: {
        idea: "かわいいは",
        result: "正義である!",
      },
      createTime: "投稿日時",
      visitsCount: "閲覧数",
      readMore: "もっと読む",
      run: "稼働時間",
      day: "日",
      hour: "時間",
      minutes: "分",
      second: "秒",
      footComment: "ミサカネットワークによって駆動される",
      return: "戻る",
    },
    archive: {
      name: "アーカイブ",
      tag: "タグ",
    },
    about: {
      name: "について",
    },
    edit: "編集",
  },
};
