import AWS, { S3 } from "aws-sdk";
import { ElNotification } from "element-plus";

let s3: S3;

function AwsUpdate(file: File): string {
  var filename = file.name;
  var suffix = filename.split(".")[1];
  var region = "tyoumisaka.cc";
  var dir = "uploadedImg/";
  var key =
    new Date().getTime() + "_" + Math.random().toFixed(2) + "." + suffix;
  var uploadParams = {
    Bucket: region,
    Key: dir + key,
    Body: file,
  };
  new Promise(function (resolve) {
    s3.upload(uploadParams, function (err: any, data: any) {
      if (err) {
        ElNotification({
          title: "Error",
          message: "Upload Failed!",
          type: "error",
        });
      }
      if (data) {
        ElNotification({
          title: "Success",
          message: "Upload Succeed!",
          type: "success",
        });
      }
    });
  });
  return "https://" + region + "/" + dir + key;
}

export default function ImageUpdate(awsConfig: any, file: File): string {
  // Set the region
  AWS.config.update({
    region: awsConfig.region,
    apiVersion: awsConfig.apiVersion,
    accessKeyId: awsConfig.accessKeyId,
    secretAccessKey: awsConfig.secretAccessKey,
  });
  //创建 aws
  s3 = new AWS.S3();

  return AwsUpdate(file);
}
