export default {
  buttons: {
    login: "Login",
  },
  menus: {
    home: {
      name: "Home",
      hitokoto: {
        idea: "かわいいは",
        result: "正義である!",
      },
      createTime: "Published on",
      visitsCount: "visits",
      readMore: "Read More",
      run: "Running",
      day: "days,",
      hour: "hours,",
      minutes: "minutes and ",
      second: "s",
      footComment: "Powered by Misaka Network",
      return: "Return",
    },
    archive: {
      name: "Archive",
      tag: "Tags",
    },
    about: {
      name: "About",
    },
    edit: "Edit",
  },
};
