/**
 * Article リクエストType
 */
export type ArticleEditModel = {
  // uuid
  uuid: string | null;
  // タイトル
  title: string;
  // 主題
  theme: string;
  // コンテンツ
  content?: string | null;
  // タグ
  tags?: Array<string> | null;
  // 言語Id
  language?: string | null;
  // 削除フラグ
  deleteFlag: boolean;
};

/**
 * 初期化された{ArticleEditModel}を取得します。
 * @returns
 */
export const initArticleEditModel = (
  theme: string,
  language: string
): ArticleEditModel => {
  const initializedParam: ArticleEditModel = {
    // uuid
    uuid: null,
    // タイトル
    title: "",
    // 主題
    theme: theme,
    // コンテンツ
    content: null,
    // タグ
    tags: [],
    // 言語Id
    language: language,
    // 削除フラグ
    deleteFlag: false,
  };

  return structuredClone(initializedParam);
};

/**
 * Article編集 リクエストTypes
 */
export type ArticleEditRequest = {
  articleList: ArticleEditModel[];
  draftFlag: boolean;
};

/**
 * 初期化された{ArticleEditRequest}を取得します。
 * @returns
 */
export const initArticleEditRequest = (): ArticleEditRequest => {
  const initializedParam: ArticleEditRequest = {
    articleList: [],
    draftFlag: false,
  };

  return structuredClone(initializedParam);
};
