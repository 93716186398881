/**
 * ログイン リクエストModel
 */
export type LoginModel = {
  // uuid
  ownerId: string;
  // タイトル
  privateKey: string;
};

/**
 * 初期化された{LoginModel}を取得します。
 * @returns
 */
export const initLoginModel = (
  username: string,
  password: string
): LoginModel => {
  const initializedParam: LoginModel = {
    // uuid
    ownerId: username,
    // タイトル
    privateKey: password,
  };

  return structuredClone(initializedParam);
};
