import { LanguageParam } from "@/api/apitypes/request/CommonRequest";

/**
 * Article検索 リクエストTypes
 */
export type ArticleSearchRequest = {
  uuid?: string;
  // タイトル
  title?: string;
  // 主題
  theme?: string;
  // タグ
  tag?: string;
  // 言語
  language?: string;
  // 削除フラグ
  deleteFlag: boolean;
  // 隠すフラグ
  draftFlag: boolean;
};

/**
 * Key検索 リクエストTypes
 */
export type KeySearchRequest = LanguageParam & {
  key: string;
};

/**
 * 初期化された{KeySearchRequest}を取得します。
 * @returns
 */
export const initKeySearchRequest = (): KeySearchRequest => {
  const initializedParam: KeySearchRequest = {
    key: "",
    language: localStorage.getItem("lang") as string,
  };

  return structuredClone(initializedParam);
};

/**
 * 初期化された{ArticleSearchRequest}を取得します。
 * @returns
 */
export const initArticleSearchRequest = (
  language?: string
): ArticleSearchRequest => {
  const initializedParam: ArticleSearchRequest = {
    uuid: "",
    title: "",
    theme: "",
    tag: "",
    language: language || (localStorage.getItem("lang") as string),
    deleteFlag: false,
    draftFlag: false,
  };

  return structuredClone(initializedParam);
};

/**
 * ページングでArticle検索 リクエストTypes
 */
export type ArticlePagingSearchRequest = LanguageParam & {
  // ページサイズ
  pageSize: number;
  // ページインディクス
  pageNo: number;
};

/**
 * 初期化された{ArticlePagingSearchRequest}を取得します。
 * @returns
 */
export const initArticlePagingSearchRequest =
  (): ArticlePagingSearchRequest => {
    const initializedParam: ArticlePagingSearchRequest = {
      pageSize: 5,
      pageNo: 1,
      language: localStorage.getItem("lang") as string,
    };

    return structuredClone(initializedParam);
  };
