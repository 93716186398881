import { getPermissions } from "@/api/functions/edit";
import ImageUpdate from "./AwsUtils";

class MyUploadAdapter {
  loader: any;
  editor: any;
  model: any;
  config: any;
  uploaFile: any;
  constructor(loader: any, editor: any) {
    // 上载期间的加载器实例
    this.loader = loader;
    this.editor = editor;
  }

  // 启动上载
  async upload(url: string) {

    var file: Promise<File> = this.loader.file;

    await file.then((res) => {
      this.uploaFile = res;
    });

    var imgUrl = "";
    await getPermissions()
      .then((res) => {
        this.config = res.data.data;
        imgUrl = ImageUpdate(this.config, this.uploaFile);
      })
      .catch((err: any) => {});

    return { default: imgUrl };
  }
}

export function MyCustomUploadAdapterPlugin(editor: any) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader: any) => {
    return new MyUploadAdapter(loader, editor);
  };
}

export default MyUploadAdapter;
