import {
  createRouter,
  createWebHistory,
  RouterOptions,
  Router,
  RouteRecordRaw,
} from "vue-router";
import HomeView from "@/views/HomeView.vue";
import EditView from "@/views/EditView.vue";
import MainContent from "@/components/homeComponents/MainContent.vue";
import DetailContent from "@/components/homeComponents/DetailContent.vue";
import SearchContent from "@/components/homeComponents/SearchContent.vue";
import ArchiveContent from "@/components/homeComponents/ArchiveContent.vue";


const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
    children: [
      {
        path: "",
        name: "lists",
        component: MainContent,
        props: true,
      },
      {
        path: "/:theme",
        name: "detail",
        component: DetailContent,
        props: true,
      },
      {
        path: "search",
        name: "search",
        component: SearchContent,
        props: true,
      },
      {
        path: "archive",
        name: "archive",
        component: ArchiveContent,
        props: true,
      },
    ],
  },
  { path: "/edit/:theme?", name: "edit", component: EditView },
];

const options: RouterOptions = {
  history: createWebHistory(),
  routes,
};

const router: Router = createRouter(options);

export default router;
