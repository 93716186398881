export default {
  buttons: {
    login: "登录",
  },
  menus: {
    home: {
      name: "主页",
      hitokoto: {
        idea: "かわいいは",
        result: "正義である!",
      },
      createTime: "发表于",
      visitsCount: "查看",
      readMore: "阅读全文",
      run: "已运行",
      day: "天",
      hour: "小时",
      minutes: "分",
      second: "秒",
      footComment: "由御坂网络强力驱动",
      return: "返回",
    },
    archive: {
      name: "归档",
      tag: "标签",
    },
    about: {
      name: "关于",
    },
    edit: {
      name: "编辑",
    },
  },
};
