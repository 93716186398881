import { createI18n } from "vue-i18n";
import zh from "./zh";
import ja from "./ja";
import en from "./en";

const language = (localStorage.getItem("lang") || navigator.language || "zh")
  .split("-")[0]
  .toLocaleLowerCase();
localStorage.setItem("lang", language);

// 创建 i18n
const i18n = createI18n({
  legacy: false,
  globalInjection: true, // 全局模式
  locale: localStorage.getItem("lang") || language || "zh",
  messages: {
    zh,
    ja,
    en,
  },
});

export default i18n;
