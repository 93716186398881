import { KeySearchRequest } from "../apitypes/request/ArticleSearchRequest";
import { KeySearchResults } from "../apitypes/response/ArticleResponse";
import Request from "../axios";
import { AxiosResponse } from "axios";

const request = new Request({
  // baseURL: import.meta.env.BASE_URL,
  timeout: 1000 * 60 * 5,
  interceptors: {
    // 请求拦截器
    requestInterceptors: (config) => config,
    // 响应拦截器
    responseInterceptors: (result: AxiosResponse) => {
      return result;
    },
  },
});

//keyでArticle検索
export const keySearchArticles = async (params: KeySearchRequest) => {
  return await request.post<KeySearchResults>(
    "/provider/searchArticles",
    params
  );
};
